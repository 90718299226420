import React, { Component } from 'react';
// import resume from "../Images/Shenae-Simmons-Portfolio-Resume.pdf";


class Resume extends Component {
  render() {
    return (

  <div>
    <iframe src="https://docdro.id/Qixrk1g" width="600px" height="800px" align="center" overflow="scroll"/>


  </div>
        );
  }
}

export default Resume;